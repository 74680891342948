import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

import breathalyzerSpecs from '../../../assets/img/breathalyzer-specs.png'
import catchphoto from '../../../assets/img/product_catchphoto.jpg'
import breathVideo1 from '../../../assets/video/products_video_1.mp4'
import breathVideo2 from '../../../assets/video/products_video_2.mp4'
import breathImg1 from '../../../assets/img/products_img_1.jpg'
import breathImg2 from '../../../assets/img/products_img_2.jpg'
import { Carousel } from '../components/Carousel'
import { Link } from 'react-router-dom'

export function Products() {
    const breathImgs = [breathImg1, breathImg2]
    const breathVideos = [breathVideo1, breathVideo2]

    return (
        <div id='container'>
            <Header />

            <div id='catchphoto-container'>
                <img
                    id='breathalyzer-img'
                    src={catchphoto}
                />
            </div>

            <div className='content-container'>
                <section className='content-section' id='breathalyzer'>
                    <p>
                        O detector de álcool SIGHIR DAD01 é a solução ideal para frotas de veículos, oferecendo flexibilidade e confiabilidade em diferentes condições operacionais.
                        Com recursos avançados como parametrização via aplicativo, sensor substituível e integração com sistemas veiculares, o SIGHIR garante uma monitorização precisa
                        da sobriedade dos motoristas, promovendo a segurança nas estradas. A facilidade de manutenção, os testes randômicos e a conectividade digital fazem do SIGHIR um
                        aliado indispensável para a gestão de frotas, reduzindo acidentes e assegurando operações mais seguras e eficientes.
                    </p>
                    
                    <Link to='/contact-us'>
                        <button className='primary-button' style={{ width: '15%' }}>
                            Solicite uma cotação
                        </button>
                    </Link>                                                

                    <div id='product-chars-container'>
                        <div>
                            <label htmlFor='toggle-first-char'>
                                <p>Parametrização via APP</p>
                                <FontAwesomeIcon icon={faChevronDown} className='toggle-indicator'/>
                            </label>
                            
                            <input type="checkbox" id="toggle-first-char" style={{ display: 'none' }} />

                            <div>
                                <p>
                                    O dispositivo permite configuração personalizada através de um aplicativo móvel, facilitando a adaptação às necessidades específicas de cada operação de transporte.
                                    O app possui três funções principais: administrador, instalador e motorista. O modo administrador conta com funções para ativar e desativar o DAD01, monitorar dados
                                    e logs para possíveis manutenções e também a geração de contra-senhas e ajuste de parâmetros. Já o modo instalador tem como principal objetivo ser uma ferramenta do
                                    instalador para gerar relatórios comprobatórios de instalação, contendo check-lists e anexos de fotos. Por último, o modo motorista é próprio para casos em que é
                                    necessário um suporte especializado remoto, permitindo monitoramento de logs e análise de possíveis problemas.
                                </p>
                            </div>
                        </div>

                        <div>
                            <label htmlFor='toggle-second-char'>
                                <p>Calibração otimizada</p>
                                <FontAwesomeIcon icon={faChevronDown} className='toggle-indicator'/>
                            </label>
                            
                            <input type="checkbox" id="toggle-second-char" style={{ display: 'none' }} />

                            <div>
                                <p>
                                    O dispositivo possui uma memória interna que armazena os dados de calibração do sensor, eliminando a necessidade de interromper a operação do veículo para manutenção. 
                                    Em caso de falha, basta substituir o sensor, sem necessidade de recalibração manual ou parada prolongada, garantindo a continuidade das operações com mínima interrupção.
                                </p>
                            </div>
                        </div>

                        <div>
                            <label htmlFor='toggle-third-char'>
                                <p>Testes randômicos durante a viagem</p>
                                <FontAwesomeIcon icon={faChevronDown} className='toggle-indicator'/>
                            </label>
                            
                            <input type="checkbox" id="toggle-third-char" style={{ display: 'none' }} />

                            <div>
                                <p>
                                    Para evitar fraudes e garantir a conformidade, o dispositivo solicita re-testes randômicos ao motorista durante a viagem. Esses testes adicionais são realizados sem aviso 
                                    prévio, assegurando que o sistema esteja sendo utilizado corretamente e que todos os procedimentos de segurança estejam sendo seguidos.
                                </p>
                            </div>
                        </div>

                        <div>
                            <label htmlFor='toggle-fourth-char'>
                                <p>Integração com outros sistemas</p>
                                <FontAwesomeIcon icon={faChevronDown} className='toggle-indicator'/>
                            </label>
                            
                            <input type="checkbox" id="toggle-fourth-char" style={{ display: 'none' }} />

                            <div>
                                <p>
                                    O dispositivo é compatível com uma variedade de sistemas, como telemetria, câmeras, e outros acessórios, permitindo uma gestão centralizada dos ativos. 
                                    Essa integração facilita o monitoramento e a análise em tempo real, garantindo que todas as operações sejam geridas de forma eficiente e coesa em uma única plataforma.
                                </p>
                            </div>
                        </div>

                        <div>
                            <label htmlFor='toggle-fith-char'>
                                <p>Facilidade de uso</p>
                                <FontAwesomeIcon icon={faChevronDown} className='toggle-indicator'/>
                            </label>
                            
                            <input type="checkbox" id="toggle-fith-char" style={{ display: 'none' }} />

                            <div>
                                <p>
                                    Desenvolvido com foco no usuário, o dispositivo oferece uma interface intuitiva que simplifica os processos operacionais. Todas as funcionalidades foram projetadas pensando na facilidade de uso, 
                                    reduzindo a necessidade de treinamento extensivo e permitindo que os operadores utilizem o sistema de maneira rápida e eficiente.
                                </p>
                            </div>
                        </div>

                        <div>
                            <label htmlFor='toggle-sixth-char'>
                                <p>Suporte especializado</p>
                                <FontAwesomeIcon icon={faChevronDown} className='toggle-indicator'/>
                            </label>
                            
                            <input type="checkbox" id="toggle-sixth-char" style={{ display: 'none' }} />

                            <div>
                                <p>
                                    Em caso de eventuais falhas no produto, os usuários podem consultar a central de suporte para obter ajuda imediata. A central pode fornecer orientações para liberar o veículo rapidamente, minimizando o 
                                    tempo de inatividade e garantindo que as operações voltem ao normal o mais rápido possível.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div id='breath-specs'>
                        <p>Especificações técnicas</p>

                        <img 
                            src={breathalyzerSpecs}
                            style={{
                                width: '100%',
                                maxWidth: 700,
                                minWidth: 300
                            }}
                        />
                    </div>

                    <div id='breath-imgs-videos'>
                        <p>Imagens e Vídeos</p>
                        
                        <Carousel
                            sources={breathImgs}
                            type='image'
                        />

                        <div style={{ marginTop: 40, marginBottom: 40 }}/>

                        <Carousel
                            sources={breathVideos}
                            type='video'
                        />
                    </div>
                </section>
            </div>

            <Footer/>
        </div>
    )
}