
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import logoSighir from '../../../../assets/img/logo/sighir_branco.png'

export function Header() {
    return (
        <div id='header-container'>
            <div id='header-content'>
                <Link to='/' id='logo'>
                    <img
                        src={logoSighir}
                        height={20}
                    />
                </Link>

                <input type="checkbox" id="toggle-side-panel" style={{ display: 'none' }} />
                <input type="checkbox" id="toggle-company-dropdown" style={{ display: 'none' }} />

                <div id='info-container'>
                    <div id='panel-container'>
                        <div id='close-side-panel'>
                            <label htmlFor='toggle-side-panel'>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    color='white'
                                    size='sm'
                                />
                            </label>
                        </div>

                        <div id='left-panel'>
                        <a></a>
                        <a></a>
                            <Link to='/about'>Empresa</Link>
                            <Link to='/products'>Produtos</Link>
                            {/* <a>Notícias</a> */}
                            
                        </div>

                        <div id='right-panel'>
                            <Link to='/Etilometro'>
                                <button>Área do cliente</button>
                            </Link>
                            
                            <Link to='/contact-us'>
                                <button >Contate-nos</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <label id='open-side-panel' htmlFor='toggle-side-panel'>
                    <FontAwesomeIcon
                        icon={faBars}
                        color='white'
                        size='sm'
                    />
                </label>
            </div>
        </div>
    )
}