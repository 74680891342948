import DownloadList from '../../../../components/DownloadList'
import TopBar from '../../../../components/TopBar'
import './styles.css'

export default function AnexosPage(){
    const manuals = [
        {title: 'Aplicativo Mobile do Etilômetro (Android)', link: 'https://play.google.com/store/apps/details?id=com.sighir.monitor'},
        {title: 'Aplicativo Mobile do Etilômetro (Apple)', link: 'https://apps.apple.com/br/app/sighir-monitor/id6738956065?l=en-GB'},
        // {title: 'Aplicativo Desktop do Etilômetro (Windows)', link: 'http://3.92.136.22:8000/desktop/'},
    ]

    return (
        <div className='MainContainer'>
            <TopBar/>

            <div style={{padding: '1rem'}}/>

            <DownloadList
                data={manuals}
                icon={'file'}
                title='Documentação - Anexos'
            />
        </div>
    )
}