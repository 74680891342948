import { useEffect, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Country, State, City, ICountry, ICity, IState } from 'country-state-city'

import './styles.css'
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { url } from '../../../utils/database';
import { getCookie } from '../../../utils';

export function ContactUs() {
    const faPropInstagram = faInstagram as IconProp
    const countries = Country.getAllCountries()

    const [isBusy, setIsBusy] = useState(false)

    const [states, setStates] = useState<IState[]>([])
    const [cities, setCities] = useState<ICity[]>([])
    const [selCountry, setSelCountry] = useState<ICountry>()
    const [selState, setSelState] = useState<IState>()
    const [selCity, setSelCity] = useState(-1)

    const industryOptions = [
        { label: 'Logística', value: '1' },
        { label: 'Automotiva', value: '2' },
        { label: 'Alimentos e Bebidas', value: '3' },
        { label: 'Mineração', value: '4' },
        { label: 'Química/Petroquímica', value: '5' },
        { label: 'Construção Civil', value: '6' },
        { label: 'Papel e Celulose', value: '7' },
        { label: 'Outro', value: '8' }
    ]

    const reasonOptions = [
        { label: 'Cotação', value: '1' },
        { label: 'Dúvida', value: '2' },
        { label: 'Reclamação', value: '3' },
        { label: 'Outro', value: '4' }
    ]

    function handleCountryChange(e: any) {
        const isoCode = e.target.value
        const targetCountry = countries.find(c => c.isoCode == isoCode)
        setSelCountry(targetCountry)
    }

    function handleStateChange(e: any) {
        const isoCode = e.target.value
        const targetState = states.find(s => s.isoCode == isoCode)
        setSelState(targetState)  
    }

    function handleCityChange(e: any) {
        const index = e.target.value
        setSelCity(index)  
    }

    function setRelatedStates() {
        setStates(State.getStatesOfCountry(selCountry?.isoCode))
    }

    function setRelatedCities() {
        if (selCountry === undefined || selState === undefined)
            return

        setCities(City.getCitiesOfState(selCountry?.isoCode, selState?.isoCode))
    }

    function handlePhoneInputChange(e: any) {
        let input = e.target.value.replace(/\D/g, '') // Remove tudo que não for dígito
        if (input.length > 0)
            input = '+' + input

        e.target.value = input
    }

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        setIsBusy(true)
        e.preventDefault()

        const currentTarget = e.currentTarget
        const formData = new FormData(currentTarget)

        const industry = industryOptions.find(ind => ind.value === formData.get('industry'))?.label
        const reason = reasonOptions.find(r => r.value === formData.get('reason'))?.label
        const city = selCity !== -1 ? cities[selCity].name : ''

        const newData = {
            name: formData.get('name'),
            lastName: formData.get('lastName'),
            company: formData.get('company'),
            phone: formData.get('phoneNumber'),
            email: formData.get('email'),
            industry: industry,
            country: selCountry?.name,
            state: selState?.name,
            city: city,
            reason: reason,
            message: formData.get('message'),
        }

        try {
            const response = await fetch(`${url}api/mail/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'X-CSRFToken': getCookie('csrftoken') ?? ''
                },
                body: JSON.stringify(newData),
                //credentials: 'include'
            })
    
            setIsBusy(false)
    
            if (!response.ok)
                alert('Ocorreu um erro ao enviar sua requisição')
            else
               alert('Requisição enviada com sucesso! Enviaremos uma resposta o mais rápido possível')
        }
        catch (error) {
            const e = error as Error
            alert(e.message)
        }

        // Limpa as variáveis do formulário
        currentTarget.reset()
        
        const industrySelect = currentTarget.querySelector('#industry') as HTMLSelectElement
        const countrySelect = currentTarget.querySelector('#country') as HTMLSelectElement
        const reasonSelect = currentTarget.querySelector('#reason') as HTMLSelectElement
        industrySelect.value = ''
        countrySelect.value = ''
        reasonSelect.value = ''

        setSelCountry(undefined)
        setSelState(undefined)
        setSelCity(-1)
    }

    useEffect(() => {
        setRelatedStates()

        setSelState(undefined)
        setSelCity(-1)
        setCities([])
    }, [selCountry])

    useEffect(() => {
        setRelatedCities()
        setSelCity(-1)
    }, [selState])

    return (
        <div id='container'>
            <Header />

            <main className="content-container">
                <section id='contact-us-info'>
                    <span>Contate-nos</span>

                    <span  style={{ color: 'black' }}>
                        Sighir Enterprise LTDA <br/>
                        Estrada Velha do Pilar, 1675 - Duque de Caxias
                    </span>

                    <div>
                        <div>
                            <FontAwesomeIcon icon={faPhoneVolume} size='xl' />
                            <span style={{ color: 'black' }}>+55 (21) 97185-0354</span>
                        </div>

                        <div>
                            <FontAwesomeIcon icon={faEnvelope} size='xl' />
                            <span style={{ color: 'black' }}>contato@sighir.com.br</span>
                        </div>

                        <div>
                            <FontAwesomeIcon icon={faPropInstagram} size='xl' />
                            <span style={{ color: 'black' }}>@sighir.oficial</span>
                        </div>
                    </div>
                </section>

                <section id='contact-us-form'>
                    <p>Formulário de contato</p>

                    <form className="form-grid" method='post' action={`${url}api/mail/`} onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Nome*</label>
                            <input type="text" id="name" name="name" required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="lastName">Sobrenome*</label>
                            <input type="text" id="lastName" name="lastName" required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="company">Empresa*</label>
                            <input type="text" id="company" name="company" required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="phoneNumber">Telefone de contato*</label>
                            <input 
                                type="tel" 
                                id="phoneNumber" 
                                name="phoneNumber" 
                                placeholder="Com o código do país"
                                inputMode='numeric'
                                maxLength={18}
                                onChange={handlePhoneInputChange}
                                required 
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">E-mail*</label>
                            <input type="email" id="email" name="email" required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="industry">Indústria*</label>
                            <select id="industry" name="industry" required>
                                <option value="" disabled selected>Selecione um setor industrial</option>
                                { industryOptions.map((ind => <option value={ind.value} key={ind.value}>{ind.label}</option> )) }
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="country">País*</label>
                            <select id="country" name="country" required onChange={handleCountryChange}>
                                <option value="" disabled selected>Selecione um país</option>
                                { countries.map((country, index) => (<option value={country.isoCode} key={index}>{country.name}</option>)) }
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="state">Estado / Região*</label>
                            <select id="state" name="state" required onChange={handleStateChange} value={selState !== undefined ? selState?.isoCode : ''}>
                                <option value="" disabled selected>Selecione um estado</option>
                                { states.map((state, index) => (<option value={state.isoCode} key={index}>{state.name}</option>)) }
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="city">Cidade*</label>
                            <select id="city" name="city" required  onChange={handleCityChange} value={selCity}>
                                <option value={-1} disabled selected>Selecione uma cidade</option>
                                { cities.map((city, index) => (<option value={index} key={index}>{city.name}</option>)) }
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="reason">Motivo da solicitação*</label>
                            <select id="reason" name="reason" required>
                                <option value="" disabled selected>Selecione um motivo</option>
                                { reasonOptions.map((r => <option value={r.value} key={r.value}>{r.label}</option> )) }
                            </select>
                        </div>

                        <div className="form-group full-width">
                            <label htmlFor="message">Mensagem*</label>
                            <textarea id="message" name="message" rows={4} required></textarea>
                        </div>

                        <div className='full-width'>
                            <button 
                                disabled={isBusy} 
                                className="primary-button"
                                style={isBusy ? { opacity: .7, cursor: 'not-allowed' } : undefined}
                                type="submit"
                            >
                                { isBusy ? <div className='loadingCircle'/> : 'Enviar' }
                            </button>
                        </div>
                    </form>
                </section>
            </main>

            <Footer />
        </div>
    )
}