	import { useState } from 'react';
	import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';
	import { useInterval } from '../../../utils';

	export default function CustomHalfDonutChart({
		data = [],
		constWidth = 35,
		constHeight = 23,
		totalData = 0, // Recebe o total diretamente
	}: any) {
		const [width, setWidth] = useState(window.innerWidth);
		const [height, setHeight] = useState(window.innerHeight);

		useInterval(updateDimensions, 200);
		const propDimWidth = Math.floor(Math.sqrt(width * width + height * height)) / constWidth;
		const propDimHeight = Math.floor(Math.sqrt(width * width + height * height)) / constHeight;

		function updateDimensions() {
			if (window.innerWidth != width) setWidth(window.innerWidth);

			if (window.innerHeight != height) setHeight(window.innerHeight);
		}

		const COLORS = ['#FF9796', '#00FF58', '#B2FDFD', '#00434B'];

		return (
			<ResponsiveContainer width="100%" height="100%">
				<PieChart>
					<Pie
						data={data}
						cx="50%"
						cy="50%"
						startAngle={360}
						endAngle={0}
						innerRadius={propDimWidth}
						outerRadius={propDimHeight}
						fill="#fff"
						paddingAngle={0}
						dataKey="value"
					>
						{data.map((entry: any, index: any) => (
							<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
						))}
					</Pie>
					<Tooltip
						content={({ active, payload }) => {
							if (active && payload && payload.length) {
								return (
									<div style={{ backgroundColor: "#2c2c2c", padding: "10px", borderRadius: "5px", boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)" }}>
										{payload.map((entry, index) => (
											<p key={`item-${index}`} style={{ color: '#fff', margin: 0 }}>
												{entry.name}: <strong>{entry.value}</strong>
											</p>
										))}
									</div>
								);
							}
							return null;
						}}
					/>
					<Legend
						verticalAlign="bottom"
						align="center"
						content={({ payload }) => (
							<div style={{ display: 'flex', justifyContent: 'center', gap: '15px', marginTop: '10px' }}>
								{payload?.map((entry, index) => (
									<div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
										<div
											style={{
												width: '10px',
												height: '10px',
												borderRadius: '50%',
												backgroundColor: entry.color,
											}}
										/>
										<span style={{ color: '#fff', fontSize: '14px' }}>{entry.value}</span>
									</div>
								))}
							</div>
						)}
					/>
					<text
						x="50%"
						y="40%"
						textAnchor="middle"
						dominantBaseline="middle"
						style={{
							fontSize: 'calc(8px + 1.5vw)',
							fontWeight: 'bold',
							fill: '#fff',
						}}
					>
						{totalData}
					</text>
					<text
						x="50%"
						y="50%"
						textAnchor="middle"
						dominantBaseline="middle"
						style={{
							fontSize: 'calc(6px + 1vw)',
							fontWeight: 'normal',
							fill: '#fff',
						}}
					>
						testes
					</text>

				</PieChart>
			</ResponsiveContainer>
		);
	}
