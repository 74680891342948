import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faSearch, faAngleLeft, faAngleRight, faFilePdf, faSheetPlastic } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';
import LoadingDiv from '../../../components/LoadingDiv';
import TopBar from '../../../components/TopBar';
import { getCompanies, getConditionedLogs, getDatabaseLogs, getVehiclePlates } from '../../../utils/database';
import CustomDropBox from '../../../components/CustomDropBox';
import { Table, TableCell, TableRow } from '../../../components/Table';
import { saveJsonData, handleExportExcel, handleExportPDF, sleep, getStoredJson } from '../../../utils';
import { DateSelector, formatTimestamp, isTimestampBetween, sortArrayDatetime } from '../../../components/DatePicker';

const events = [
    {label: 'Leitura adiada', value: '$ETEV15!'},
    {label: 'Álcool detectado', value: '$ETEV30'},
    {label: 'Teste randômico não realizado', value: '$ETEV33!'},
    {label: 'Veículo desbloqueado', value: '$ETEV01!'},
    {label: 'Veículo bloqueado', value: '$ETEV02!'},
    {label: 'Motor ligado', value: '$ETEV03!'},
    {label: 'Motor desligado', value: '$ETEV04!'},
    {label: 'Etilômetro assoprado', value: '$ETEV05!'},
    {label: 'Código inserido', value: '$ETEV06!'},
    {label: 'Parâmetros atualizados', value: '$ETEV07!'},
    {label: 'Dispositivo inicializado', value: '$ETEV08!'},
    {label: 'Sensor inicializado', value: '$ETEV09!'},
    {label: 'Firmware atualizado', value: '$ETEV10!'},
    {label: 'Timeout para assopro', value: '$ETEV11!'},
    {label: 'Aviso de teste randômico', value: '$ETEV12!'},
    {label: 'Keep alive', value: '$ETEV13!'},
    {label: 'Embalagem violada', value: '$ETEV14!'},
    {label: 'Leitura realizada', value: '$ETEV16!'},
    {label: 'Keep alive recebido', value: '$ETEV17!'},
    {label: 'Sensor inválido', value: '$ETEV19!'},
    {label: 'Sensor liberado', value: '$ETEV20!'},
    {label: 'Sensor vencido', value: '$ETEV21!'},
    {label: 'Liberado por Tempo de manobra', value: '$ETEV22!'},
    {label: 'Parâmetros internos atualizados', value: '$ETEV23!'},
    {label: 'Sensor danificado', value: '$ETEV24!'},
    {label: 'Teste randômico realizado', value: '$ETEV25!'},
    {label: 'Liberado por senha', value: '$ETEV26!'},
    {label: 'Temperatura Alta', value: '$ETEV27!'},
    {label: 'Temperatura Grave', value: '$ETEV28!'},
    {label: 'Álcool não detectado', value: '$ETEV29!'},
    {label: 'Ignição acionada', value: '$ETEV31!'},
    {label: 'Troca de motorista', value: '$ETEV32!'},
    // {label: 'Comando: Desbloquear', value: '$ETBL010000!'},
    // {label: 'Comando: Bloquear', value: '$ETBL020000!'},
    {label: 'Etilômetro Reiniciado', value: '$ETRS!'},
];

const companyTypes = [
    { label: 'Transportadora', value: 'transportation' },
    { label: 'Rastreamento', value: 'telemetry' }
]

type ItemType = {
    label: string,
    value: string
}

export default function HomePage(){

    const user = getStoredJson('user', {})
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)

    const [logs, setLogs] = useState<any>([])
    const [rows, setRows] = useState<any>([])
    const [conditionedLogs, setConditionedLogs] = useState<any>([])

    const [vehicles, setVehicles] = useState<any>([])
    const [companies, setCompanies] = useState<ItemType[]>([])
    const [selectedVehicle, setSelectedVehicle] = useState<any>({})

    const [filteredVehicles, setFilteredVehicles] = useState<any>([])
    const [filteredCompanies, setFilteredCompanies] = useState<ItemType[]>([])
    
    const [selectedEvent, setSelectedEvent] = useState<any>({})
    const [selectedCompany, setSelectedCompany] = useState<any>({})
    const [selectedCompanyType, setSelectedCompanyType] = useState<any>({})

    const [inicialDate, setInitialDate] = useState<string>('')
    const [finalDate, setFinalDate] = useState<string>('')
    
    const [showItems, setShowItems] = useState([0, 30])
    const [update, setUpdate] = useState<boolean>(true)

    useEffect(() => {
        importData()
    }, [])

    useEffect(() => {
        filterConditions()
    }, [selectedCompany, selectedCompanyType])

    const navigate = useNavigate()
    
    async function importLogs(){
        const data = await getConditionedLogs()
        const response = await getDatabaseLogs();
        let rows       = []
        let filteredRows = []
        //alert(JSON.stringify(data))

        for(let item of response){
            for(let row of item.event.split('\r\n')){
                row = row.trim()

                if(row.length > 0 && item.etilometer.telemetry.id === '44922499000')
                    rows.push({...item, event: row})

                //alert(JSON.stringify(item))
            }
        }

        if (user.company !== 'SIGHIR ENTERPRISE LTDA')
            filteredRows = rows.filter((item: any) => item.etilometer.device.company.label === user.company)
        else{
            filteredRows = rows
            setIsSuperAdmin(true)
         }  

        setLogs(sortArrayDatetime(filteredRows, 'timestamp'))
        setConditionedLogs(data)
    }

    async function importData() {
        let companies = await getCompanies()
        let vehicles
        let userCmp
        let condLogs

        if (user.company !== 'SIGHIR ENTERPRISE LTDA'){
            userCmp = companies.filter((item: any) => item.label === user.company)
            
            vehicles = await getVehiclePlates(userCmp[0].id)
            condLogs = await getConditionedLogs(userCmp[0].id) 

            setSelectedCompany(userCmp[0])
        } else {
            setIsSuperAdmin(true)
            vehicles = await getVehiclePlates()
            condLogs = await getConditionedLogs()
        }

        setCompanies(companies)
        setFilteredCompanies(companies)
        setVehicles(vehicles)
        setFilteredVehicles(vehicles)
        setConditionedLogs(condLogs)
        //setShowItems(condLogs)

        setUpdate(false)
    }

    async function filterLogs() {
        setUpdate(true)
        let condLogs: any
        let companyId = ''
        let vehicleId = ''
        let initialTime = ''
        let finalTime = ''
        let slEvent = ''
        let cmpType = ''

        if (selectedCompany)
            companyId = selectedCompany.id
        if (selectedVehicle)
            vehicleId = selectedVehicle.label
        if (inicialDate)
            initialTime = inicialDate
        if (finalDate)
            finalTime = finalDate
        if (selectedEvent)
            slEvent = selectedEvent.value
        if (selectedCompanyType)
            cmpType = selectedCompanyType.value

        condLogs = await getConditionedLogs(companyId, vehicleId, slEvent, cmpType, initialTime, finalTime);
        
        setConditionedLogs(condLogs)
        setUpdate(false)
    }

    async function filterConditions() {
        let vehicles

        if (selectedCompany)
            vehicles = await getVehiclePlates(selectedCompany.id)

        setFilteredVehicles(vehicles)

    }

    async function updateRows() {
        await sleep(500)

        if (logs.length == 0)
            return

        let data = logs
        
        if(selectedCompany.label)
            data = data.filter((item: any) => item.etilometer.device.company.label.toLowerCase() == selectedCompany.label.toLowerCase())
            
        if(selectedVehicle.label)
            data = data.filter((item: any) => item.etilometer.vehicle_plate.toLowerCase() == selectedVehicle.label.toLowerCase())

        if (selectedEvent.label) {
            data = data.filter((item: any) => 
                item.event.toLowerCase().includes(selectedEvent.value.toLowerCase())
            );
        }

        if(inicialDate.length > 0 || finalDate.length > 0)
            data = data.filter((item: any) => isTimestampBetween(item.timestamp, inicialDate, finalDate))
        
        if (selectedCompanyType.label)
            data = data.filter((item: any) => item.etilometer.device.company.type === selectedCompanyType.value)

        setRows(data)   
        setUpdate(false)
    }

    function handlePrevious(){
        let start = showItems[0]
        let end   = showItems[1]

        start -= 30
        end -= 30

        if (end > conditionedLogs.length) {
            end = conditionedLogs.length
            start = conditionedLogs.length - 30
        }

        if (start < 0) {
            start = 0
            end = 30
        }

        setShowItems([start, end])
    }

    function handleNext(){
        let start = showItems[0]
        let end   = showItems[1]

        start += 30
        end  += 30

        if(end > conditionedLogs.length){
            end = conditionedLogs.length
            start = conditionedLogs.length - 30
        }

        if(start < 0){
            start = 0
            end   = 30
        }

        setShowItems([start, end])
    }

    function renderInputs(){
        return (
            <div className="DataFillContainer" data-is-super-admin={isSuperAdmin}>
                <div>
                    <div>
                        <DateSelector
                            value={inicialDate}
                            onChange={setInitialDate}
                            placeholder='Data Inicial'
                        />
                    </div>

                    <div>
                        <DateSelector
                            value={finalDate}
                            onChange={setFinalDate}
                            placeholder='Data Final'
                        />
                    </div>
                </div>

                {isSuperAdmin && (
                    <div>
                        <div>
                            <CustomDropBox
                                options={companyTypes}
                                value={selectedCompanyType}
                                setValue={setSelectedCompanyType}
                                placeholder='Tipo de Empresa'
                            />
                        </div>
                        
                        <div>
                            <CustomDropBox
                                options={filteredCompanies}
                                value={selectedCompany}
                                setValue={setSelectedCompany}
                                placeholder='Empresa'
                            />
                        </div>
                    </div>
                )}

                <div>
                    <div>
                        <CustomDropBox
                            options={filteredVehicles}
                            value={selectedVehicle}
                            setValue={setSelectedVehicle}
                            placeholder='Placa do Veículo'
                        />
                    </div>

                    <div>
                        <CustomDropBox
                            options={events}
                            value={selectedEvent}
                            setValue={setSelectedEvent}
                            placeholder='Evento'
                        />
                    </div>
                </div>
            </div>
        )
    }

    function handleItemSelect(item: any){
        if(!item)
            return

        saveJsonData('infoLog', item)
        navigate('/Etilometro/Info')
    }

    const formatEvent = (event: any) => {
        const match = event.replace(/\s+/g, '').match(/ETEV30(\d+)!?/);
    
        if (match && match[1]) {
            let num = match[1].padStart(4, '0');
            let formattedValue = `${parseInt(num) / 1000} mg/L`; 
            return `Álcool Detectado (${formattedValue})`;
        }
        
        return event;
    };
    
    return (
        <div className="MainContainer">
            <TopBar/>

            <div className='CentralBlock'>
                <div style={{padding: '.5rem'}}/>

                {renderInputs()}

                <div className='HomeInfoContainer'>
                    <button className='buttonInfoContainer' onClick={() => filterLogs()}>
                        {/* <FontAwesomeIcon icon={faSearch} style={{paddingRight: '7%'}}/> */}
                        <p style={{fontSize: 'calc(8px + .5vw)'}}>Consultar</p>
                    </button>

                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                        <Table>
                            <TableRow id='main-table-header'>
                                <TableCell>Placa do veículo</TableCell>
                                <TableCell>Empresa</TableCell>
                                <TableCell>Tipo de veículo</TableCell>
                                <TableCell>Eventos</TableCell>
                                <TableCell>Data do recebimento do evento</TableCell>
                            </TableRow>

                            {update && <div style={{padding: '.7rem'}}/>}
                            {/* {alert(JSON.stringify(rows))} */}
                            
                            <LoadingDiv loading={update}>
                                {   
                                    conditionedLogs.slice(showItems[0], showItems[1]).map((item: any) => {
                                        if( item.event.startsWith('$') && item.event.endsWith('!') && item.event && item.event !== "$ETBL020000!" && item.event !== "$ETBL010000!")
                                        return (
                                            <TableRow>
                                                <TableCell>{item.vehicle_plate}</TableCell>
                                                <TableCell>{item.company_label}</TableCell>
                                                <TableCell>{item.vehicle_type}</TableCell>
                                                <TableCell>{events.find((event) => event.value === item.event)?.label || formatEvent(item.event)}</TableCell>
                                                <TableCell>{formatTimestamp(item.timestamp)}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </LoadingDiv>
                        </Table>
                    </div>
                    
                    <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div className='buttonsHomeNav'/>
                                    
                        {/* <div style={{display: 'flex', width: '30%', minWidth: 200, justifyContent: 'space-between'}}>
                            <button className='buttonInfoContainer' style={{width: '45%'}} onClick={() => handleExportExcel(rows)}>
                                <p style={{fontSize: 'calc(8px + .4vw)'}}>Exportar Excel</p>
                            </button>

                            <button className='buttonInfoContainer' style={{width: '45%'}} onClick={() => handleExportPDF(rows)}>
                                <p style={{fontSize: 'calc(8px + .4vw)'}}>Exportar PDF</p>
                            </button>
                        </div> */}
                        
                        <div className='buttonsHomeNav'>
                            <button 
                                className='arrowButton' 
                                onClick={handlePrevious}
                                disabled={showItems[0] <= 0}
                            >
                                <FontAwesomeIcon icon={faAngleLeft} color='white'/>
                            </button>
                                
                            <button 
                                className='arrowButton' 
                                onClick={handleNext}
                                disabled={showItems[1] > (conditionedLogs.length - showItems[0])}
                            >
                                <FontAwesomeIcon icon={faAngleRight} color='white'/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}