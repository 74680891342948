import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopBar from "../../../../components/TopBar";
import { faMobileAlt, faSearch, faTruck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import './styles.css';
import { useEffect, useState } from "react";
import { getStoredJson, saveJsonData, sleep, useInitialCheck } from "../../../../utils";
import { getDataBaseCondition, getDatabaseLogs, getDatabaseRows, getLogsStats, getServerGraphs } from "../../../../utils/database";
import CustomPieChart from "../../../../components/Graphs/CustomPieChart";
import RenderMap from "../../../../components/Graphs/Map";
import { formatTimestamp, sortArrayDatetime } from "../../../../components/DatePicker";
import { Table, TableCell, TableRow } from '../../../../components/Table';
import LoadingDiv from "../../../../components/LoadingDiv";
import CustomDropBox from "../../../../components/CustomDropBox";
import ScrollView from "../../../../components/ScrollView";
import { standardData, emptyData } from "../sample";
import { Height } from "@mui/icons-material";
import { border, height, width } from "@mui/system";
import ProgressBar from "../../../../components/Graphs/CustomLinearProgress";


export default function SensorPage() {
    
    const user = getStoredJson('user', {})
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)

    const [update, setUpdate] = useState<boolean>(false)
    const [webData, setWebData] = useState<any>(standardData)

    const [deprecatedSensors, setDeprecatedSensors] = useState<any>([])
    const [almostDeprecatedSensors, setAlmostDeprecatedSensors] = useState<any>([])
    const [deprecatedSelected, setDeprecatedSelected] = useState<boolean>(false)
    const [almostDeprecatedSelected, setAlmostDeprecatedSelected] = useState<boolean>(false)

    const [sensors, setSensors] = useState<any>([])
    const [filteredSensors, setFilteredSensors] = useState<any>([])
    const [sortedSensors, setSortedSensors] = useState<any>([])

    const [totalBlows, setTotalBlows] = useState<any>(-1)

    const [lastSync, setLastSync] = useState<any>([])

    const [companies, setCompanies] = useState<any>([])
    const [selectedCompany, setSelectedCompany] = useState<any>({})

    const [plates, setPlates] = useState<any>([])
    const [selectedPlate, setSelectedPlate] = useState<any>({})
    const [filteredPlates, setFilteredPlates] = useState<any>([])

    const [logs, setLogs] = useState<any>([])
    
    const [filteredEtl, setFilteredEtl] = useState<any>([])
    const [filteredEtlFirmware, setFilteredEtlFirmware] = useState<any>([])


    const navigate = useNavigate()
    // useInitialCheck(importData)
    // useInitialCheck(updateGraphs)

    useEffect(() => {
            getLogs()
    }, [])

    useEffect(() => {
        filterData()
    }, [selectedCompany])

    async function getLogs() {
        setUpdate(true); 

        const {isSuper, sensorResponse} = await importLogs(); 
        await importData(isSuper, sensorResponse); 

        await sleep(500)
        setUpdate(false);
    }

    async function importData(isSuper: boolean, sensorResponse: any){
        const {response, filteredCompany} = await importCompanies(isSuper)
        await importPlates(response, isSuper)
        await filterLogs(sensorResponse, filteredCompany, isSuper, response);
    }

    async function importLogs(){
        //const response = await getDatabaseLogs();
        const sensorResponse = await getDatabaseRows('sensors');
        let isSuper = false

        setSensors(sensorResponse)

        if (user.company === 'SIGHIR ENTERPRISE LTDA'){
            setIsSuperAdmin(true)
            isSuper = true
        }

        return {isSuper, sensorResponse};
    }

    async function importCompanies(isSuper: boolean){
        let response = await getDatabaseRows('etilometers')
        let filteredCompany: any

        const target = Array.from(
            new Map(
                response.map((item: any) => [item.device.company.id, { 
                    label: item.device.company.label, 
                    id: item.device.company.id 
                }])
            ).values()
        );

        setCompanies(target)
        setFilteredEtl(response)
        // alert(JSON.stringify(response[0].software_version))
        // alert(JSON.stringify(response[0].need_update))

        if (!isSuper){
            filteredCompany = target.filter((item: any) => item.label === user.company)
            setSelectedCompany(filteredCompany[0])
            filteredCompany = filteredCompany[0]
        }    

        return {response, filteredCompany}
    }

    async function importPlates(response: any, isSuper: boolean){
        let target   = response.map((item: any) => ({label: item.vehicle_plate, company: item.device.company.label}))
        if (!isSuper)
            target = target.filter((item: any) => item.company === user.company)
        setPlates(target)
        setFilteredPlates(target)
    }

    async function filterLogs(sensors: any, filteredCompany?: any, isSuper?: any, filteredEtil?: any) {
        let filteredSensors: any
        let filteredSensorIds: any
        let totalBlows: any
        let filteredFirmware: any

        filteredSensors = sensors
        filteredFirmware = filteredEtil

        if (Object.keys(selectedPlate).length > 0)
            totalBlows =  await getLogsStats(filteredCompany.id, selectedPlate.label)
        else
            totalBlows = -1

        if (!isSuper && filteredCompany && Object.keys(filteredCompany).length > 0){
            filteredSensorIds = filteredEtil.filter((item: any) => item.device.company.id === filteredCompany.id).map((item: any) => item.device.sensor_id)
            filteredSensors = sensors.filter((item: any) => filteredSensorIds.includes(item.sensor_id))
            filteredFirmware = filteredEtil.filter((item: any) => item.device.company.id === filteredCompany.id)
        }

        if (isSuper && filteredCompany && Object.keys(filteredCompany).length > 0) {
            filteredSensorIds = filteredEtil.filter((item: any) => item.device.company.id === filteredCompany.id).map((item: any) => item.device.sensor_id)
            filteredSensors = sensors.filter((item: any) => filteredSensorIds.includes(item.sensor_id))
            filteredFirmware = filteredEtil.filter((item: any) => item.device.company.id === filteredCompany.id)

        }
        
        if (selectedPlate && Object.keys(selectedPlate).length > 0){
            filteredSensorIds = filteredEtl.filter((item: any) => item.vehicle_plate === selectedPlate.label).map((item: any) => item.device.sensor_id)
            filteredSensors = sensors.filter((item: any) => filteredSensorIds.includes(item.sensor_id))
            filteredFirmware = filteredEtil.filter((item: any) => item.vehicle_plate === selectedPlate.label)
        }

        let lastSync = logs[0] ? logs[0].timestamp : 'NA'
        setLastSync(lastSync)
    
        setTotalBlows(totalBlows)

        setFilteredEtlFirmware(filteredFirmware)
        setFilteredSensors(filteredSensors)
        await filterSensors(filteredSensors, filteredEtil)

    }

    async function filterSensors(filteredSens: any, filteredEtil: any) {
        let uniqueSensors = Array.from(
            new Map(
                filteredSens
                    .map((sensor: any) => {
                        let missingMonths = calculateMonthsPassed(sensor.timestamp)
                        
                        // Encontre o veículo relacionado ao sensor atual
                        const etl = filteredEtil.filter((it: any) => it.device.sensor_id === sensor.sensor_id);
                        const vehiclePlate = etl[0]?.vehicle_plate || null; // Obtenha a vehicle_plate, se existir
                        
                        // Retorne apenas sensores com vehicle_plate
                        if (vehiclePlate) {
                            return [
                                sensor.sensor_id,
                                { 
                                    ...sensor, 
                                    vehicle_plate: vehiclePlate, 
                                    missingMonths: missingMonths
                                }
                            ];
                        }
        
                        return null; // Retorna null para sensores sem vehicle_plate
                    })
                    .filter((item: any) => item !== null) // Remove os sensores com vehicle_plate === null
            ).values()
        );

        let deprecatedSensors = uniqueSensors.filter((item: any) => item.missingMonths < 1)
        let almostDeprecatedSensors = uniqueSensors.filter((item: any) => item.missingMonths < 6)

        if (almostDeprecatedSelected)
            uniqueSensors = almostDeprecatedSensors

        if (deprecatedSelected)
            uniqueSensors = deprecatedSensors

        let sortedSensors = [...uniqueSensors].sort((a: any, b: any) => a.missingDays - b.missingDays);

        setSortedSensors(sortedSensors)
        setDeprecatedSensors(deprecatedSensors)
        setAlmostDeprecatedSensors(almostDeprecatedSensors)
    }

    function filterData() {
        if (Object.keys(selectedCompany).length > 0)
            setFilteredPlates(plates.filter((item: any) => item.company === selectedCompany.label))
        else
            setFilteredPlates(plates)
    }

    async function updateDash() {
        setUpdate(true)

        await filterLogs(sensors, selectedCompany, isSuperAdmin, filteredEtl)

        await sleep(500)
        setUpdate(false)
    }

    function renderLeftContainer() {
        return (
            <ScrollView className='ControlContainer' style={{ width: '28%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 1)' }}>
                <div style={{ width: '100%', height: '15%', display: 'flex', justifyContent: 'space-between' }}>
                    <div
                        className="carIconContainer"
                        onClick={() => navigate('/Etilometro/Control')}
                    >
                        <FontAwesomeIcon icon={faTruck} className="carIcon" style={{ color: '#383838' }}/>
                    </div>

                    <div
                        className="carIconContainer"
                        style={{ backgroundColor: '#00FF58' }}
                    >
                        <FontAwesomeIcon icon={faMobileAlt} className="carIcon" />
                    </div>
                </div>

                <div style={{ padding: '1rem' }} />

                {isSuperAdmin && (
                    <>
                        <div style={{ width: '100%' }}>
                            <CustomDropBox
                                options={companies}
                                value={selectedCompany}
                                setValue={setSelectedCompany}
                                placeholder='Empresa'
                            />
                        </div>

                        <div style={{ padding: '.7rem' }} />
                    </>
                )}

                <div style={{ width: '100%' }}>
                    <CustomDropBox
                        options={filteredPlates}
                        value={selectedPlate}
                        setValue={setSelectedPlate}
                        placeholder={Object.keys(selectedCompany).length === 0 ? 'Placa do Veículo (Selecione uma Empresa)' : 'Placa do Veículo'}
                        disabled={Object.keys(selectedCompany).length === 0}
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ color: 'white', alignSelf: 'flex-start', fontSize: 'calc(5px + .5rem)', fontWeight: 600 }}>
                    Vencimento do Sensor
                </div>

                <div style={{ padding: '.3rem' }} />

                <div
                    style={{ display: 'flex', width: '100%', cursor: 'pointer' }}
                    onClick={() => setDeprecatedSelected(!deprecatedSelected)}
                >
                    <div
                        className="buttonTravel"
                        style={{ backgroundColor: deprecatedSelected ? '#00FF58' : '#121212', border: '1px solid white', borderRadius: '10px' }}
                    >
                        <span style={{ color: deprecatedSelected ? 'black' : 'white' }}>Vencidos</span>
                    </div>
                </div>

                <div style={{ padding: '.3rem' }} />

                <div
                    style={{ display: 'flex', width: '100%', cursor: 'pointer' }}
                    onClick={() => setAlmostDeprecatedSelected(!almostDeprecatedSelected)}
                >
                    <div
                        className="buttonTravel"
                        style={{ backgroundColor: almostDeprecatedSelected ? '#00FF58' : '#121212', border: '1px solid white', borderRadius: '10px' }}
                    >
                        <span style={{ color: almostDeprecatedSelected ? 'black' : 'white' }}>Vencimento Próximo</span>
                    </div>
                </div>

                <div style={{ padding: '1rem' }} />

                <button className='buttonInfoContainer' onClick={async () => await updateDash()} style={{ width: '50%' }}>
                    {/* <FontAwesomeIcon icon={faSearch} style={{ paddingRight: '7%' }} /> */}
                    <p style={{ fontSize: 'calc(5px + .5vw)' }}>Consultar</p>
                </button>
            </ScrollView>
        )
    }

    function renderPieLabel(label: string, color: string) {
        return (
            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: color }} />

                <div style={{ width: '85%', fontSize: 'calc(5px + .5vh)', color: 'white' }}>
                    {label}
                </div>
            </div>
        )
    }

    function calculateMonthsPassed(timestamp: string): number {
        // Obtém a data do timestamp
        const dateFromTimestamp = new Date(timestamp); // Converte para objeto Date
        const currentDate = new Date(); // Data atual
    
        // Calcula a diferença em anos e meses
        const yearDiff = currentDate.getFullYear() - dateFromTimestamp.getFullYear();
        const monthDiff = currentDate.getMonth() - dateFromTimestamp.getMonth();
    
        // Total de meses decorridos
        const totalMonthsElapsed = yearDiff * 12 + monthDiff;
    
        // Total de meses em 2 anos (24 meses)
        const monthsToTwoYears = 24 - totalMonthsElapsed;
    
        // Retorna o número de meses restantes para completar 2 anos
        return monthsToTwoYears > 0 ? monthsToTwoYears : 0; // Evita números negativos
    }
    
    

    function formatEvents(isFirmware = false) {
        if (isFirmware){
            const parseVersion = (version: string): number[] =>
                version.split('.').map(num => parseInt(num, 10));
              
            const sortedEtl = [...filteredEtlFirmware].sort((a, b) => {
                const versionA = parseVersion(a.software_version);
                const versionB = parseVersion(b.software_version);
            
                for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
                const numA = versionA[i] || 0;
                const numB = versionB[i] || 0;
                if (numA !== numB) return numA - numB;
                }
                return 0;
            }).filter((item: any) => item.need_update === true)

            return (
                <div className="scrollable-div">
                    {sortedEtl.map((item: any, index: number) => (
                        <div key={index}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '10px 0',
                                }}
                            >
                                <div style={{ fontWeight: 'bold', color: 'white', fontSize: '12px' }}>{item.vehicle_plate}</div>
                                <div style={{ display: 'flex', flexDirection: 'column', color: 'white', fontSize: '14px', textAlign: 'center' }}>
                                    {'Atualização Necessária'}
                                </div>
                                <div
                                    style={{
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        backgroundColor: item.need_update === true ? '#F03D59' : '#00FF58',
                                        color: 'black',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        width: '14%',
                                        textAlign: 'center'
                                    }}
                                >
                                    {item.software_version}
                                </div>
                                
                            </div>
                            {index < sortedEtl.length - 1 && ( 
                                <div
                                    style={{
                                        height: '1px',
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                        width: '100%',
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div className="scrollable-div">
                {sortedSensors.map((item: any, index: number) => (
                    <div key={index}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '10px 0',
                            }}
                        >
                            <div style={{ fontWeight: 'bold', color: 'white', fontSize: '12px' }}>{item.sensor_id}</div>
                            <div style={{ display: 'flex', flexDirection: 'column', color: 'white', fontSize: '14px', textAlign: 'center' }}>
                                {item.vehicle_plate}
                            </div>
                            <div
                                style={{
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                    backgroundColor: item.missingMonths < 1 ? '#F03D59' : item.missingMonths < 6 ? '#FF9796' : '#00FF58',
                                    color: 'black',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    width: '16%',
                                    textAlign: 'center'
                                }}
                            >
                                {item.missingMonths} meses
                                {/* {formatTimestamp(item.timestamp).split(',')[0]} */}
                            </div>
                            
                        </div>
                        {index < sortedSensors.length - 1 && ( 
                            <div
                                style={{
                                    height: '1px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    width: '100%',
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
        );
    }

    function renderRightContainer() {
        return (
            <div className='ControlContainer' style={{ width: '60%', justifyContent: 'space-between', padding: 0 }}>
                <div style={{ display: 'flex', width: '100%', height: '15%', justifyContent: 'space-between' }}>
                    <LoadingDiv loading={update} className='controlItem' style={{ width: '32%' }}>
                        <span style={{ fontWeight: 'normal' }}>
                            Total de Sensores
                        </span>
                        <span style={{ fontSize: '40px' }}>
                            {sortedSensors.length}
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '32%' }}>
                        <span style={{ fontWeight: 'normal' }}>
                            Próximos a Vencer
                        </span>
                        <span style={{ fontSize: '40px' }}>
                            {almostDeprecatedSensors.length}
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '32%' }}>
                        <span style={{ fontWeight: 'normal' }}>
                            Vencidos
                        </span>
                        <span style={{ fontSize: '40px' }}>
                            {deprecatedSensors.length}
                        </span>
                    </LoadingDiv>

                    {/* <LoadingDiv loading={update} className='controlItem'>
                        <span>
                            Último Sincronismo
                        </span>
                        <br />
                        <span>
                            {lastSync ? formatTimestamp(lastSync) : ''}
                        </span>
                    </LoadingDiv> */}
                </div>

                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: '80%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', width: '49%', height: '100%', justifyContent: 'space-between' }}>
                        <LoadingDiv loading={update} className='controlItem' style={{ width: '100%', height: '100%' }}>
                            <div style={{ fontWeight: 'normal' }}>
                                Vencimento de Sensores Instalados
                            </div>

                            <div style={{ padding: '15px' }} />

                            {formatEvents()}
                        </LoadingDiv>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '49%', height: '100%', justifyContent: 'space-between' }}>
                        <LoadingDiv loading={update} className='controlItem' style={{ width: '100%', height: '30%' }}>
                            <div style={{ fontWeight: 'normal' }}>
                                Total de Sopros Realizados
                            </div>

                            {totalBlows >= 0 ? 
                            (
                                <>
                            
                                <div style={{ fontSize: '30px', marginBottom: '1%' }}>
                                    {totalBlows}
                                </div>
                                </>
                            ) : (
                                <>
                                <div style={{ padding: '4%' }} />
                            
                                <div style={{ fontSize: '25px', marginBottom: 10 }}>
                                    Selecione uma placa
                                </div>
                                </>
                            )}
                            {totalBlows >= 0 && (<ProgressBar value={totalBlows} />)}

                        </LoadingDiv>
                        
                        <LoadingDiv loading={update} className='controlItem' style={{ width: '100%', height: '66%' }}>
                            <div style={{ fontWeight: 'normal' }}>
                                Versão de Firmware dos Dispositivos
                            </div>

                            <div style={{ padding: '15px' }} />

                            {formatEvents(true)}
                        </LoadingDiv>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="MainContainer">
            <TopBar />
            <div style={{ padding: '1rem' }} />

            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                {renderLeftContainer()}

                <div style={{ padding: '1.5%' }} />

                {renderRightContainer()}
            </div>

        </div>
    )
}