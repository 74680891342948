import DownloadList from '../../../../components/DownloadList'
import TopBar from '../../../../components/TopBar'
import './styles.css'
import installationPDF from '../../../../assets/manuals/installation.pdf'
import userPDF from '../../../../assets/manuals/user.pdf'

export default function ManualsPage(){
    const manuals = [
        {title: 'Manual de Instalação', link: installationPDF},
        {title: 'Manual do Usuário', link: userPDF},
    ]

    return (
        <div className='MainContainer'>
            <TopBar/>  

            <div style={{padding: '1rem'}}/>

            <DownloadList
                data={manuals}
                icon={'pdf'}
                title='Documentação - Manuais'
            />
        </div>
    )
}