import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CustomGroupBarChart({
  time1 = {}, // Primeiro conjunto de dados
  time2 = {}, // Segundo conjunto de dados
  color1 = "#00ff58", // Cor da primeira barra
  color2 = "#FF9796", // Cor da segunda barra
}: any) {
  // Obtém a data de 7 dias atrás
  const oneMonthAgo = new Date();
  oneMonthAgo.setDate(oneMonthAgo.getDate() - 30); // Subtrai 7 dias

  // Combinar os dados de time1 e time2 com base na data
  const mergedData = Object.keys({ ...time1, ...time2 })
    .map((date) => ({
      date, // Data no formato string
      value1: time1[date] || 0, // Valor do primeiro conjunto (0 se ausente)
      value2: time2[date] || 0, // Valor do segundo conjunto (0 se ausente)
    }))
    .filter((entry) => {
      const entryDate = new Date(entry.date);
      return entryDate >= oneMonthAgo; // Filtra apenas datas da última semana
    })
    .sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateA - dateB; // Ordena do mais antigo para o mais recente
    });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={mergedData}
        margin={{ top: 0, right: 40, left: 0, bottom: 0 }}
        barCategoryGap="10%"
        barGap={0}
      >
        <CartesianGrid strokeDasharray="0" stroke="#ccc" opacity={0.3} vertical={false} />
        <XAxis
          dataKey="date"
          tick={{ fontSize: "calc(8px + 0.5vh)", fill: "#fff" }}
          tickFormatter={(date) => {
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year.slice(-2)}`;
          }}
        />
        <YAxis tick={{ fontSize: "calc(8px + 0.5vh)", fill: "#fff" }} tickLine={false} axisLine={false} />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              const formattedDate = label.split("-").reverse().join("/");
              return (
                <div style={{ backgroundColor: "#2c2c2c", padding: "10px", borderRadius: "5px", boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)" }}>
                  <p style={{ color: "#fff", marginBottom: "5px" }}>
                    <strong>Data: {formattedDate}</strong>
                  </p>
                  {payload.map((entry, index) => (
                    <p key={`item-${index}`} style={{ color: "#fff", margin: 0 }}>
                      {entry.name}: <strong>{entry.value}</strong>
                    </p>
                  ))}
                </div>
              );
            }
            return null;
          }}
        />
        <Legend
          verticalAlign="top"
          iconType="circle"
          wrapperStyle={{
            paddingBottom: "10px",
          }}
          content={({ payload }) => (
            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
              {payload?.map((entry, index) => (
                <div key={`item-${index}`} style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: entry.color,
                    }}
                  />
                  <span style={{ color: "fff", fontSize: "14px" }}>{entry.value}</span>
                </div>
              ))}
            </div>
          )}
        />
        <Bar dataKey="value1" fill={color1} name="Testes Iniciais" radius={[10, 10, 0, 0]} />
        <Bar dataKey="value2" fill={color2} name="Testes Randomicos" radius={[10, 10, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
}
