import { v4 as uuidv4 } from 'uuid'

export const DEBUG = false
export const url   = DEBUG ? 'http://localhost:8000/' : 'https://sighir.com:8000/'


export async function postWebData(urlRequest: string, data: any, timeout: number = 30000) {
	const controller = new AbortController()
	const id = setTimeout(() => controller.abort(), timeout)
	console.log('URL AQUI: ' + urlRequest)
	const options = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(data),
		signal: controller.signal
	}
	
	try {
		const response = await fetch(urlRequest, options);
		clearTimeout(id);
		
		console.log("Status HTTP da resposta:", response.status); // Adicione este log
	
		if (!response.ok) {
			try {
				const errorResponse = await response.json();
				console.error("Erro da API:", errorResponse);
				//alert("Erro da API: " + JSON.stringify(errorResponse));
			} catch {
				console.error("Erro desconhecido da API");
				//alert("Erro desconhecido da API");
			}
			return null;
		}
	
		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
		return null;
	}
	
}

export async function getWebData(urlRequest: any, timeout = 30000) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    // Adiciona os dados como parâmetros na URL
    const queryParams = new URLSearchParams({ tableName: 'logs' }).toString();
    const fullUrl = `${url}${urlRequest}?${queryParams}`;

    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        signal: controller.signal,
    };

	try {
        const response = await fetch(fullUrl, options);
        clearTimeout(id);

        if (!response.ok) 
            return null;

        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
}  

export async function fetchApiData(request: any) {
	try {
		const response = await fetch(url + request + '/');
		const data = await response.json();
		return data
	}
	catch (error) {
		console.log('Erro ao buscar dados:' + error)
		return {status: 'error', data: String(error)}
	}
}

export async function postApiData(data: any, request: string) {
	const response = await postWebData(url + request + '/', data)

	if(!response)
		return {status: 'error', data: 'error on post'}

	return response
}

export async function getDatabaseRows(tableName: string, maxRows: null|number=null) {
	let data: any  = {tableName: tableName}

	if(maxRows)
		data.limit = maxRows

	const response = await postApiData(data, 'api/getRows')

	if(!response)
		return []

	if(response.status != 'success')
		return []

	if(response.data.length == 0)
		return []

	return response.data
}

export async function getDatabaseLogs() {
	const response = await getWebData('api/getLogs/')

	if(!response)
		return []

	if(response.status != 'success')
		return []

	if(response.data.length == 0)
		return []

	return response.data
}

export async function getConditionedLogs(company?: any, vehicle_plate?: any, event?: any, company_type?: any, initial_date?: any, final_date?: any) {
	const data = {
		company: company,
		vehicle_plate: vehicle_plate,
		event: event,
		company_type: company_type,
		initial_date: initial_date,
		final_date: final_date,
	}

	const response = await postApiData(data, 'api/getConditionedLogs')

	if(!response || response.status != 'success')
		return []

	return response.data
}

export async function getDataBaseCondition(tableName: string, columnName: string, desiredValue: string) {
	const data = {
		tableName: tableName,
		columnName: columnName,
		desiredValue: desiredValue,
	}

	const response = await postApiData(data, 'api/getCondition')

	if(!response || response.status != 'success')
		return []

	return response.data
}

export async function getLogsStats(company: string, vehicle_plate?: string) {
	let data = {
		company: company,
		vehicle_plate: vehicle_plate,
	}
	//alert(JSON.stringify(data))

	const response = await postApiData(data, 'api/getLogsStats')

	if(!response || response.status != 'success')
		return []

	return response.data.numTests
}

export async function getDashData(company?: string, vehicle_plate?: string, initial_date?: any, final_date?: any) {
	let data = {
		company: company,
		vehicle_plate: vehicle_plate,
		initial_date: initial_date,
		final_date: final_date
	}

	const response = await postApiData(data, 'api/getDashData')

	if(!response || response.status != 'success')
		return []

	return response.data
}

export async function getAlertEvents(company?: string, vehicle_plate?: string, initial_date?: any, final_date?: any) {
	let data = {
		company: company,
		vehicle_plate: vehicle_plate,
		initial_date: initial_date,
		final_date: final_date
	}

	const response = await postApiData(data, 'api/getAlertEvents')

	if(!response || response.status != 'success')
		return []

	return response.data
}

export async function getDataBaseConditionMultiple(tableName: string, columnName1: string, desiredValue1: string, columnName2: string, desiredValue2: string) {
	const data = {
		tableName: tableName,
		columnName1: columnName1,
		value1: desiredValue1,
		columnName2: columnName2,
		value2: desiredValue2
	}
	
	const response = await postApiData(data, 'api/getDataMultiple')

	if(!response || response.status != 'success')
		return []

	return response.data
}

export async function editDatabaseByCondition(tableName: string, columnName: string, desiredValue: string, newData: any) {
	const data = {
		tableName: tableName,
		columnName: columnName,
		desiredValue: desiredValue,
		newData: newData
	}

	return await postApiData(data, 'api/editData')
}

// export async function addDataBaseRow(tableName: string, newData: any) {
// 	const data = {
// 		tableName: tableName,
// 		newData: newData
// 	}
	
// 	return await postApiData(data, 'api/addData')
// }

export async function editDataBaseRow(tableName: string, newData: any, id: any) {
	const data = {
		tableName: tableName,
		newData: newData,
		id: id
	}
	
	return await postApiData(data, 'api/changeRow')
}

export async function editDatabaseRowsByColumn(tableName: string, columnName: string, value: string, newValue: string){
	const data = {
		tableName: tableName,
		columnName: columnName,
		value: value,
		newValue: newValue
	}

	return await postApiData(data, 'api/editRows')
}

export async function deleteDatabaseRow(tableName: string, columnName: string, value: string){
	const data = {
		key: '%%*$%%*$*$(*((%%*$*$(*($*$!%!$*$!%!', 
		tableName: tableName, 
		columnName: columnName,
		value: value
	}
	
	return await postApiData(data, 'api/deleteRow')
}

export async function getServerGraphs(data: any){
	try{
		const response = await postApiData(data, 'api/getGraphs')
		return response
	}
	catch(e){
		return null
	}
}

export function genUUID(size: number=30){
	const uuid = uuidv4()
	return uuid.substring(0, size)
}

export async function getCompanies() {
	let data = {}

	const response = await postApiData(data, 'api/getCompanies')

	if(!response || response.status != 'success')
		return []

	return response.data
}

export async function getVehiclePlates(company?: string) {
	let data = {
		"company": company
	}

	const response = await postApiData(data, 'api/getVehicles')

	if(!response || response.status != 'success')
		return []

	return response.data
}