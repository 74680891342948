import { useEffect, useState } from 'react';
import CustomTextField from '../../../../components/CustomTextField';
import './styles.css';
import logo from '../../../../assets/img/logo/sighir_verde.png';
import { getDataBaseCondition } from '../../../../utils/database';
import { getStoredJson, saveJsonData, sleep } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import AsyncButton from '../../../../components/AsyncButton';

export default function LoginPage(){
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const [isLogged, setIsLogged] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        initialCheck()
    }, [])

    useEffect(() => {
        fetchCompanies()
    }, [isLogged])

    async function handleKeyPress(event: any) {
        if (event.key != 'Enter')
                return

        setLoading(true)
        await sleep(2000)
        await handleLogin()
        setLoading(false) 
    }

    async function initialCheck(){
        const user = getStoredJson('user', {})
        
        if (!user.timestamp)
            return

        return navigate('/Etilometro/Control')
    }

    async function fetchCompanies() {
        const response = await getDataBaseCondition('companies', 'type', 'transportation')
        saveJsonData('companies', response)
    }

    async function handleLogin(){
        const response = await getDataBaseCondition('users', 'email', email.trim())

        if(response.length == 0)
            return alert('dados incorretos')

        if(response[0].password != password.trim())
			return alert('senha incorreta')

        saveJsonData('user', {...response[0], timestamp: Date.now()})

        setIsLogged(true)
        return navigate('/Etilometro/Control')
    }

    return (
        <div className='MainContainer' style={{justifyContent: 'center'}}>
            <div className="LoginContainer">
                <img
                    id='LoginImageContainer'
                    alt=''
                    src={logo}
                />

                <div style={{padding: '5vh'}}/>

                <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
                    <div className='login-field'>
                        <CustomTextField
                            value={email}
                            setValue={setEmail}
                            placeholder='Email'
                            onKeyDown={handleKeyPress}
                        />
                    </div>

                    <div style={{padding: '.7rem'}}/>

                    <div className='login-field'>
                        <CustomTextField
                            value={password}
                            setValue={setPassword}
                            placeholder='Senha'
                            onKeyDown={handleKeyPress}
                            type='password'
                        />
                    </div>

                    <div style={{padding: '.7rem'}}/>

                    <div style={{display: 'flex'}} className='login-field'>
                        <AsyncButton
                            active={loading}
                            text={'Login'}
                            className={'loginButton'}
                            onClick={async () => {
                                setLoading(true)
                                await sleep(1000)
                                await handleLogin()
                                setLoading(false)
                            }}
                            loading={true}
                        />
                    </div>

                    <div style={{padding: '5vh'}}/>
                </div>
            </div>
        </div>
    )
}