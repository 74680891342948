import DownloadList from '../../../../components/DownloadList'
import TopBar from '../../../../components/TopBar'
import './styles.css'
import videoApp from '../../../../assets/video/video_app.mp4'
import video from '../../../../assets/video/video.mp4'
import postpone from '../../../../assets/video/postpone.mp4'
import password from '../../../../assets/video/counter_password.mp4'
import menu from '../../../../assets/video/menu.mp4'
import appInstallation from '../../../../assets/video/app_installation.mp4'
import attFirmware from '../../../../assets/video/att_firmware.mp4'
import initialTest from '../../../../assets/video/initial_test.mp4'
import generalVisionApp from '../../../../assets/video/general_app.mp4'


export default function ProceduresPage(){
    const manuals = [
        // {title: 'Introdução ao Etilômetro', link: videoApp},
        // {title: 'Inovação e Pesquisa', link: video},
        {title: 'Visão Geral do Aplicativo', link: generalVisionApp},
        {title: 'Aplicativo do Instalador', link: appInstallation},
        {title: 'Realizando Teste', link: initialTest},
        {title: 'Função Adiar', link: postpone},
        {title: 'Sistema de Contrassenha', link: password},
        {title: 'Atualização de Firmware', link: attFirmware},
        {title: 'Menu de Opções', link: menu},
    ]

    return (
        <div className='MainContainer'>
            <TopBar/>

            <div style={{padding: '1rem'}}/>

            <DownloadList
                data={manuals}
                icon={'video'}
                title='Vídeos de Instrução'
            />
        </div>
    )
}