import React, { useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

// Estilo personalizado para a barra de progresso
const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30, // Altura da barra
  borderRadius: 20, // Bordas arredondadas
  backgroundColor: '#333', // Fundo escuro
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#00FF58', // Cor do progresso
    borderRadius: 5, // Bordas arredondadas no progresso também
  },
}));

const ProgressBarWithFixedTooltip = ({ value, maxValue=5000 }: any) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  // Calcula a porcentagem do progresso
  const percentage = Math.round((value / maxValue) * 100);

  // Mostra o tooltip ao passar o mouse
  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  // Oculta o tooltip ao sair
  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <>
    <div
      style={{ width: '100%', marginTop: '10px', position: 'relative' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      

      {/* Barra de Progresso */}
      <CustomLinearProgress variant="determinate" value={percentage} />

      {/* Valores e Porcentagem */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
        <span style={{ color: '#fff', fontSize: '12px' }}></span>
        <span
          style={{
            color: '#fff',
            fontSize: '20px',
            position: 'absolute',
            left: `${percentage}%`,
            transform: 'translateX(-50%)',
          }}
        >
          {percentage}%
        </span>
        <span style={{ color: '#fff', fontSize: '20px' }}>{maxValue}</span>
      </div>
    </div>
    </>
  );
};

export default ProgressBarWithFixedTooltip;
