import { useState } from "react";
import CustomDropBox from "../../../../components/CustomDropBox";
import TopBar from "../../../../components/TopBar";
import './styles.css'
import { getDatabaseRows, postApiData } from "../../../../utils/database";
import { getStoredJson, sleep, useInitialCheck } from "../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileDownload, faFilePdf, faSearch } from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";
import LoadingDiv from "../../../../components/LoadingDiv";
import logo from '../../../../assets/img/logoSIGHIR.png'
import LoadingCircle from "../../../../components/LoadingCircle";
import { formatTimestamp } from "../../../../components/DatePicker";
import ScrollView from "../../../../components/ScrollView";
import { getParameter } from "../../../../utils/settings";

const companies = [
    {label: 'Sighir'},
    {label: 'DAF'},
    {label: 'MIX'},
    {label: 'Logika'},
    {label: 'Predileto'},
]

export default function InstalationPage(){
    const user = getStoredJson('user', {})
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)

    const [vehicles, setVehicles] = useState<any>([])
    const [selectedVehicle, setSelectedVehicle] = useState<any>({})
    const [selectedCompany, setSelectedCompany] = useState<any>({})

    const [devices, setDevices] = useState<any>([])
    const [selectedDevice, setSelectedDevice] = useState<any>({})

    const [reports, setReports] = useState<any>([])
    const [targetReports, setTargetReports] = useState<any>([])

    const [update, setUpdate] = useState<boolean>(false)
    const [busy, setBusy] = useState<boolean|string>(false)

    useInitialCheck(importOptions)

    async function importOptions(){
        setUpdate(true)

        let response = await getDatabaseRows('etilometers')
        
        if (user.company !== 'SIGHIR ENTERPRISE LTDA')
            response = response.filter((item: any) => item.device.company.label === user.company)

        setVehicles(response.map((item: any) => ({label: item.vehicle_plate})))
        setDevices(response.map((item: any) => ({label: item.device.esp_id})))

        setReports(response)
        setTargetReports(response)

        if (user.company === 'SIGHIR ENTERPRISE LTDA')
            setIsSuperAdmin(true)
        setUpdate(false)
    }

    async function handleDownloadItem(item: any) {
        setBusy(item.esp_id)
        const response = await postApiData({folderName: item.device.esp_id}, 'api/downloadImages')
        const images = response.status == 'success' ? response.data : []

        const doc = new jsPDF();
        const width  = 210 / 100
        const height = 297 / 100

        const addText = (text='', yOffset=7) => {
            doc.text(text, x, y);
            y += yOffset; 
        }

        let x = width  * 10
        let y = height * 1

        doc.addImage(logo, 'PNG', x*.95, y, width*25, height*5.5); 
        y += 9*height

        doc.setFontSize(15)
        doc.setFont('helvetica', 'bold')
        addText('Relatório de Instalação')

        doc.setFontSize(10)
        doc.setFont('helvetica', 'normal')

        const defaultSettings = JSON.parse(item.default_settings)

        addText(`Empresa: ${item.device.company.label}`);
        addText(`ID do Etilômetro: ${item.device.esp_id}`);
        addText(`ID do Sensor: ${item.device.sensor_id}`);
        addText(`Placa do Veículo: ${item.vehicle_plate}`);
        addText(`Data de Instalação: ${item.installation_date}`);
        addText(`Modo de Comunicação: ${getParameter('comm_type', defaultSettings.comm_type)}`);
        addText(`Modo de Operação: ${getParameter('operation_mode', defaultSettings.operation_mode)}`);
        addText(`Tempo de Viagem: ${defaultSettings.time_of_travel}`);
        addText(`Número de Testes: ${defaultSettings.number_of_tests}`);

        let x_img = x
        let y_img = y - 32*height

        images.forEach((image: string, index: number) => {
            x_img = (index % 3) * 28*width + x;
            y_img = (index % 3 == 0) ? y_img + height*18 : y_img 
            
            doc.addImage(image, 'PNG', x_img, y_img, width*22, height*17, undefined, 'NONE', -90)
        })

        doc.save("download.pdf");
        await sleep(1500)
        setBusy(false)
    }

    function renderReport(item: any){
        return (
            <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div className="itemContainer">
                    <div className="columnItem">
                        <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
                            <FontAwesomeIcon icon={faFilePdf} className="iconItem"/>
                            <div style={{padding: '6px'}}/>
                            <div className="itemText">{item.vehicle_plate}</div>
                        </div>
                    </div>
                    
                    <div className="columnItem">
                        <div className="itemText">{formatTimestamp(item.installation_date)}</div>
                    </div>
                    
                    <div className="columnItem" >
                        {(busy == item.esp_id) ? (<LoadingCircle/>) : (
                            <FontAwesomeIcon 
                                icon={faDownload} 
                                className={busy ? "downloadItemDisabled" : "downloadItem"} 
                                onClick={busy ? undefined : async () => await handleDownloadItem(item)}
                            />
                        )}
                    </div>
                </div>

                <div className="line"/>
            </div>
        )
    }

    async function handleFilter(){
        let target = [...reports]

        if(selectedCompany.label)
            target = target.filter((item: any) => item.company == selectedCompany.label)

        if(selectedVehicle.label)
            target = target.filter((item: any) => item.vehicle_plate == selectedVehicle.label)

        if(selectedDevice.label)
            target = target.filter((item: any) => item.esp_id == selectedDevice.label)

        setTargetReports(target)
    }

    return (
        <div className="MainContainer">
            <TopBar/>

            <div className='CentralBlock' style={{alignItems: 'center', justifyContent: 'center'}}>
                <div className="InstalationContainer">
                    <div style={{padding: '1rem'}}/>

                    <div style={{display: 'flex', width: '75%', alignItems: 'center', justifyContent: 'space-between'}}>
                        
                        {isSuperAdmin && (
                            <div style={{width: '25%'}}>
                                <CustomDropBox
                                    options={companies}
                                    value={selectedCompany}
                                    setValue={setSelectedCompany}
                                    placeholder='Empresa'
                                />
                            </div>
                        )}

                        <div style={{width: '25%'}}>
                            <CustomDropBox
                                options={vehicles}
                                value={selectedVehicle}
                                setValue={setSelectedVehicle}
                                placeholder='Veículo'
                            />
                        </div>

                        <div style={{width: '25%'}}>
                            <CustomDropBox
                                options={devices}
                                value={selectedDevice}
                                setValue={setSelectedDevice}
                                placeholder='Etilômetro'
                            />
                        </div>

                        <button 
                            className='buttonInfoContainer' 
                            onClick={async () => await handleFilter()}
                        >
                            <p style={{fontSize: 'calc(6px + .5vw)'}}>Filtrar</p>
                        </button>
                    </div>

                    <div style={{padding: '1rem'}}/>
                    
                    <ScrollView className="reports_new">
                        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                            <div className="columnItem">Nome</div>
                            <div className="columnItem">Data de Envio</div>
                            <div className="columnItem">Download</div>
                        </div>

                        <div className="line"/>

                        <LoadingDiv loading={update}>
                            {targetReports.map((item: any) => renderReport(item))}
                        </LoadingDiv>
                    </ScrollView>

                </div>
            </div>
        </div>
    )
}