import { useEffect, useState } from 'react'
import { faSync, faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.css'
import TopBar from '../../../components/TopBar'
import { ItemType, getStoredJson, sleep, useInitialCheck } from '../../../utils'
import CustomDropBox from '../../../components/CustomDropBox'
import logo from '../../../assets/img/logo/sighir_verde.png'
import { fetchApiData, getDatabaseRows, postApiData } from '../../../utils/database'
import { url } from '../../../utils/database'
import { getParameter, goodParse } from '../../../utils/settings'
import { SoftwareModal } from './SoftwareModal'
import { formatTimestamp } from '../../../components/DatePicker'

const vehicles = [
    { label: 'Caminhão', value: 0 },
    { label: 'Carro', value: 1 }
]

export default function SoftwarePage() {
    const [selectedCompany, setSelectedCompany] = useState<any>({})
    const [selectedVehicle, setSelectedVehicle] = useState<any>({})

    const [etilometers, setEtilometers] = useState<any>([])
    const [selectedEtilometer, setSelectedEtilometer] = useState<any>({})
    const [companies, setCompanies] = useState<ItemType[]>([])

    const [devices, setDevices] = useState<any>([])
    const [isBusy, setIsBusy] = useState<boolean>(false)
    const [params, setParams] = useState<any>({})
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const [firmwareLabel, setFirmwareLabel] = useState<any>({})

    const [firmwareVersion, setFirmwareVersion] = useState({
        "version": "",
        "changes": [""],
        "timestamp": ""
    })

    useEffect(() => {
        getLatestFirmwareVersion()
        importOptions()
    }, [])

    useInitialCheck(importDevices, [etilometers, selectedCompany, selectedVehicle, selectedEtilometer])

    async function importOptions() {
        let data = await getDatabaseRows('etilometers')
        setEtilometers(data.map((item: any) => ({ ...item, label: item.device.esp_id, value: item.device.esp_id })))
        
        const storedCompanies = getStoredJson('companies')
        setCompanies(storedCompanies.map((c: any) => ({ label: c.label, value: c.id })))
    }

    function unique(array: any) {
        return Array.from(new Set(array));
    }

    async function getLatestFirmwareVersion() {
        const response = await fetchApiData('api/getFirmwareVersion')
        //alert(JSON.stringify(response))
        if (response.version !== undefined)
            setFirmwareVersion(response)

        let version = await getDatabaseRows('firmwares')
        setFirmwareLabel(version[version.length - 1])
    }

    async function importDevices() {
        let data = [...etilometers]

        if (!selectedCompany.label && !selectedVehicle.label && !selectedEtilometer.label)
            return setDevices([])

        if (selectedCompany.label) 
            data = data.filter((item: any) => item.device.company.id == selectedCompany.value)

        if (selectedVehicle.label)
            data = data.filter((item: any) => item.vehicle_type == selectedVehicle.value)

        if (selectedEtilometer.label) {
            data = etilometers.filter((item: any) => item.device.esp_id == selectedEtilometer.label)
        }

        data = unique(data.map((item: any) => item.device.esp_id))
        setDevices(data)
    }

    function formatParams(data: any) {
        if (Object.keys(data).length == 0)
            return null

        data = {
            enable_random: getParameter('enable_random', data.enable_random, false),
            operation_mode: getParameter('operation_mode', data.operation_mode, false),
            comm_type: getParameter('comm_type', data.comm_type, false),
            camera: getParameter('camera', data.camera, false),
            camera_time: goodParse(data.camera_time),
            time_of_travel: goodParse(data.time_of_travel, true) * 60,
            number_of_tests: goodParse(data.number_of_tests)
        }

        let valid: any = {}

        for (let key in data) {
            if (data[key] == null || data[key] == undefined || isNaN(data[key]))
                continue

            valid[key] = data[key]
        }

        return valid
    }

    async function updateDevices() {
        setIsBusy(true)

        const data = {
            devices: devices,
            newSettings: formatParams(params)
        }

        const response = await postApiData(data, 'api/updateDevices')

        await sleep(2000)
        setIsBusy(false)

        setSelectedCompany({})
        setSelectedVehicle({})

        if (!response)
            return alert('erro ao atualizar... tente novamente')
    }

    function renderInputs() {
        return (
            <div style={{display: 'flex', width: '50%', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width: '25%'}}>
                    <CustomDropBox
                        options={vehicles}
                        value={selectedVehicle}
                        setValue={setSelectedVehicle}
                        placeholder='Veículo'
                    />
                </div>

                <div style={{width: '25%'}}>
                    <CustomDropBox
                        options={companies}
                        value={selectedCompany}
                        setValue={setSelectedCompany}
                        placeholder='Empresa'
                    />
                </div>

                <div style={{width: '25%'}}>
                    <CustomDropBox
                        options={etilometers}
                        value={selectedEtilometer}
                        setValue={setSelectedEtilometer}
                        placeholder='Etilômetro'
                    />
                </div>

                <div className='buttonInfoContainer' onClick={() => setModalOpen(true)} style={{ width: '25%' }}>
                    <p style={{fontSize: 'calc(6px + .5vw)'}}>Configurar Parâmetros</p>
                </div>
            </div>
        )
    }

    async function handleGetConfigFile() {
        const companies = getStoredJson('companies')
        const company = companies.find((c: any) => c.id === selectedCompany.value).value

        try {
            const data = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({ company: company, type: 'file' }),
            }

            const response = await fetch(url + 'api/getSettings/', data)

            if (!response.ok) {
                throw new Error('Erro ao solicitar o arquivo: ' + response.status);
            }

            const blob = await response.blob();

            const contentDisposition = response.headers.get('content-disposition');
            let filename = 'settings_' + company + '.txt'

            if (contentDisposition) {
                const matches = /filename="([^"]+)"/.exec(contentDisposition);

                if (matches !== null)
                    filename = matches[1];
            }

            const new_url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = new_url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(new_url);
            document.body.removeChild(a);
        }
        catch (error) {
            alert('Erro ao baixar o arquivo: ' + error);
        }
    }

    async function handleGetConfig() {
        const companies = getStoredJson('companies')
        alert(JSON.stringify(companies))
        const company = companies.find((c: any) => c.id === selectedCompany.value).value

        try {
            const data = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({ company: company, type: 'json' }),
            }

            const response = await fetch(url + 'api/getSettings/', data)

            if (!response.ok) {
                throw new Error('Erro ao solicitar o arquivo: ' + response.status);
            }

            setParams(await response.json())
        }
        catch (error) {
            alert('Erro ao baixar o arquivo: ' + error);
        }
    }

    return (
        <div className='MainContainer'>
            <TopBar />
            
            <SoftwareModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                params={params}
                setParams={setParams}
                onDownloadConfig={handleGetConfig}
                onDownloadConfigFile={handleGetConfigFile}
            />
            
            <div style={{ padding: '1rem' }} />

            {renderInputs()}

            <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <div className='mainSoftwareContainer'>
                    <div className='softwareContainerLeft'>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <img src={logo} alt='' style={{ height: '2.3rem' }} />
                            <div style={{ padding: '.5rem' }} />
                            <div style={{ fontSize: 'calc(10px + 1.2vw)', verticalAlign: 'top', fontWeight: 600 }}>
                                Firmware {firmwareLabel.version}
                            </div>
                        </div>

                        <div style={{ padding: '.7rem' }} />

                        <div className='aboutText' style={{ fontWeight: 'bold' }}>
                            Data de lançamento
                        </div>

                        <div style={{ padding: '.2rem' }} />

                        <div className='aboutText'>
                            {formatTimestamp(firmwareLabel.release_date).split(',')[0]}
                        </div>

                        <div style={{ padding: '.2rem' }} />

                        <div className='aboutText' style={{ fontWeight: 'bold' }}>
                            Mudanças
                        </div>

                        <div style={{ padding: '.2rem' }} />

                        
                        {firmwareLabel.desc
                            ?.trim().split('\n')
                            .map((item: any, index: any) => (
                                <div className='aboutText' key={index}>{item.trim()}</div> // Renderiza cada item em uma nova linha
                            ))}
                            </div>

                    <div className='softwareContainerRight'>
                        <span>
                            Atualizar Firmware
                        </span>

                        <div style={{ padding: '.7rem' }} />

                        <div className='versionInfoContainer'>
                            <span>
                                Dispositivos a serem atualizados
                            </span>
                        </div>

                        <div style={{ padding: '.7rem' }} />

                        <div className='itemContainerUpdate'>
                            {devices.map((device: string) => (
                                <span style={{ color: 'black' }}>
                                    {device}
                                </span>
                            ))}
                        </div>

                        <div style={{ padding: '.7rem' }} />

                        <div style={{ display: 'flex', width: '100%', justifyContent: "center" }}>
                            <div
                                className='faSyncContainer'
                                onClick={async () => await updateDevices()}
                                title='atualizar dispositivos'
                            >
                                <FontAwesomeIcon
                                    icon={faSync}
                                    style={{ opacity: .6, color: 'black' }}
                                    size='xl'
                                    className={isBusy ? 'rotate-animation' : ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}