import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopBar from "../../../../components/TopBar";
import { faCircleCheck, faCircleXmark, faMobileAlt, faTruck, faUnlock } from "@fortawesome/free-solid-svg-icons";

import './styles.css';
import { getStoredJson, sleep } from "../../../../utils";
import { editDataBaseRow, getAlertEvents, getCompanies, getDashData, getDataBaseCondition, getDatabaseLogs, getDatabaseRows, getVehiclePlates } from "../../../../utils/database";
import CustomHalfDonutChart from "../../../../components/Graphs/CustomHalfDonutChart";
import { DateSelector, formatTimestamp, sortArrayDatetime } from "../../../../components/DatePicker";
import LoadingDiv from "../../../../components/LoadingDiv";
import CustomDropBox from "../../../../components/CustomDropBox";
import ScrollView from "../../../../components/ScrollView";
import CustomModal from "../../../../components/Modal";
import CustomGroupBarChart from "../../../../components/CustomGroupBarChart";

export default function ControlPage(){

    const user = getStoredJson('user', {})
    const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false)
    
    const [inicialDate, setInitialDate] = useState<string>('')
    const [finalDate, setFinalDate] = useState<string>('')
    const [dashData, setDashData] = useState<any>()
    const [alertEvents, setAlertEvents] = useState<any>()
    const [allAlertEvents, setAllAlertEvents] = useState<any>()
    
    const [update, setUpdate] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [modalUpdate, setModalUpdate] = useState<boolean>(false)
    const [successfullCmd, setSuccessfullCmd] = useState<boolean>(false)

    const [selectedPlate, setSelectedPlate] = useState<any>({})
    const [selectedCompany, setSelectedCompany] = useState<any>({})
    const [unlockedPlate, setUnlockedPlate] = useState<any>({})

    const [companies, setCompanies] = useState<any>([])
    const [plates, setPlates] = useState<any>([])
    const [filteredPlates, setFilteredPlates] = useState<any>([])
    const [filteredEtl, setFilteredEtl] = useState<any>([])

    const [logs, setLogs] = useState<any>([])

    const [events, setEvents] = useState<any>([])

    const [selectedBlockOption, setSelectedBlockOption] = useState<any>([])

    const blockOptions = [
        {label: 'Desbloquear', key: 'has_to_unblock'},
        {label: 'Bloquear', key: 'has_to_block'}
    ]

    const navigate = useNavigate()

    useEffect(() => {
        getLogs()
    }, [])

    useEffect(() => {
        filterPlates()
    }, [selectedCompany])

    async function updateDash() {
        setUpdate(true)

        await filterLogs()

        await sleep(100)
        setUpdate(false)
    }

    async function getLogs() {
        setUpdate(true); 

        await importData(); 

        await sleep(100)
        setUpdate(false);
    }

    async function importData(){
        let companies = await getCompanies()
        let vehicles
        let dashData
        let alertEvents
        let userCmp
        
        companies = companies.filter((item: any) => item.type === "transportation")

        if (user.company !== 'SIGHIR ENTERPRISE LTDA'){
            userCmp = companies.filter((item: any) => item.label === user.company)
            
            vehicles = await getVehiclePlates(userCmp[0].id)
            dashData = await getDashData(userCmp[0].id);
            alertEvents = await getAlertEvents(userCmp[0].id);

            setSelectedCompany(userCmp[0])
        } else {
            setIsSuperAdmin(true)
            vehicles = await getVehiclePlates()
            dashData = await getDashData();
            alertEvents = await getAlertEvents();
        }
        
        const allAlertEvents = Object.values(alertEvents).flat();
        //alert(JSON.stringify(dashData))
        allAlertEvents.sort(
            (a: any, b: any) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );

        setDashData(dashData)
        setAlertEvents(alertEvents)
        setAllAlertEvents(allAlertEvents)
        setCompanies(companies)
        setPlates(vehicles)
        setFilteredPlates(vehicles)

        let response = await getDatabaseRows('etilometers')
        response = response.filter((item: any) => item.telemetry.id === '44922499000')

        setFilteredEtl(response)
    }

    async function filterLogs() {
        let dashData: any
        let alertEvents: any
        let companyId = ''
        let vehicleId = ''
        let initialTime = ''
        let finalTime = ''

        if (selectedCompany)
            companyId = selectedCompany.id
        if (selectedPlate)
            vehicleId = selectedPlate.label
        if (inicialDate)
            initialTime = inicialDate
        if (finalDate)
            finalTime = finalDate
            

        dashData = await getDashData(companyId, vehicleId, initialTime, finalTime);
        alertEvents = await getAlertEvents(companyId, vehicleId, initialTime, finalTime);
        
        const allAlertEvents = Object.values(alertEvents).flat();

        allAlertEvents.sort(
            (a: any, b: any) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );

        setDashData(dashData)
        setAlertEvents(alertEvents)
        setAllAlertEvents(allAlertEvents)
    }

    function filterPlates() {
        if (Object.keys(selectedCompany).length > 0) 
            setFilteredPlates(plates.filter((item: any) => item.company === selectedCompany.id));
    }

    function renderLeftContainer() {
        return (
            <ScrollView className='ControlContainer' style={{ width: '28%', boxShadow: '0px 4px 8px rgba(0, 0, 0, 1)' }}>
                <div style={{ width: '100%', height: '15%', display: 'flex', justifyContent: 'space-between' }}>
                    <div className="carIconContainer" style={{ backgroundColor: '#00FF58' }}>
                        <FontAwesomeIcon icon={faTruck} className="carIcon" size='sm' />
                    </div>

                    <div className="carIconContainer" onClick={() => navigate('/Etilometro/Sensor')}>
                        <FontAwesomeIcon icon={faMobileAlt} className="carIcon" style={{ color: '#383838' }} />
                    </div>
                </div>

                <div style={{ padding: '1rem' }} />

                {isSuperAdmin && (
                    <>
                    <div style={{ width: '100%' }}>
                        <CustomDropBox
                            options={companies}
                            value={selectedCompany}
                            setValue={setSelectedCompany}
                            placeholder='Empresa'
                        />
                    </div>

                    <div style={{ padding: '.7rem' }} />
                    </>
                )}

                <div style={{ width: '100%' }}>
                    <CustomDropBox
                        options={filteredPlates}
                        value={selectedPlate}
                        setValue={setSelectedPlate}
                        placeholder={Object.keys(selectedCompany).length === 0 ? 'Placa do Veículo (Selecione uma Empresa)' : 'Placa do Veículo'}
                        disabled={Object.keys(selectedCompany).length === 0}
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ width: '100%' }}>
                    <DateSelector
                        value={inicialDate}
                        onChange={setInitialDate}
                        placeholder='Data Inicial'
                    />
                </div>

                <div style={{ padding: '.7rem' }} />

                <div style={{ width: '100%' }}>
                    <DateSelector
                        value={finalDate}
                        onChange={setFinalDate}
                        placeholder='Data Final'
                    />
                </div>

                <div style={{ padding: '1rem' }} />

                <button 
                    className='buttonInfoContainer' 
                    onClick={async () => await updateDash()} 
                    style={{width: '50%', opacity: update ? 0.6 : 1, cursor: update ? 'not-allowed' : 'pointer'}}
                    disabled={update}
                >
                    {/* <FontAwesomeIcon icon={faSearch} style={{ paddingRight: '7%' }} /> */}
                    <p style={{ fontSize: 'calc(5px + .5vw)' }}>Consultar</p>
                </button>
            </ScrollView>
        )
    }

    function formatEvents() {
        return (
            <div className="scrollable-div" style={{ width: '100%' }}>
                {allAlertEvents && allAlertEvents.map((item: any, index: number) => (
                    <div key={index}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                padding: '10px 0',
                            }}
                        >
                            <div style={{ fontWeight: 'bold', color: '#fff', fontSize: '14px' }}>{item.vehicle_plate}</div>
                            <div
                                style={{
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                    backgroundColor: item.event === "Teste Randômico Não Realizado" || item.event.includes("Álcool Detectado") ? '#F03D59' : '#FF9796', 
                                    color: 'black',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {item.event}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', color: '#fff', fontSize: '14px', textAlign: 'center' }}>
                                <div>{formatTimestamp(item.timestamp).split(',')[0]}</div> {/* Data (primeira parte do timestamp) */}
                                <div>{formatTimestamp(item.timestamp).split(',')[1]}</div> {/* Hora (segunda parte do timestamp) */}
                            </div>
                        </div>
                        {index < events.length - 1 && ( // Adiciona a linha separadora apenas entre os itens
                            <div
                                style={{
                                    height: '1px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Linha opaca
                                    width: '100%',
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
        );
    }

    function handleModal() {
        setModalOpen(!modalOpen)
        setModalUpdate(false)
        setSuccessfullCmd(false)
    }

    function renderRightContainer() {
        return (
            <div className='ControlContainer' style={{ width: '60%', justifyContent: 'space-between', padding: 0 }}>
                <div style={{ display: 'flex', width: '100%', height: '15%', justifyContent: 'space-between' }}>
                    <LoadingDiv loading={update} className='controlItem' style={{ width: '35%' }}>
                        <span style={{ fontWeight: 'normal' }}>
                            Última Sincronização
                        </span>
                        <span style={{ fontSize: '35px' }}>
                            {dashData ? formatTimestamp(dashData.lastSync) : ''}
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '26%' }}>
                        <span style={{ fontWeight: 'normal' }}>
                            Quantidade de Dispositivos Instalados
                        </span>
                        <span style={{ fontSize: '40px' }}>
                            {dashData ? dashData.installed : ''}
                        </span>
                    </LoadingDiv>

                    <LoadingDiv loading={update} className='controlItem' style={{ width: '35%' }}>
                        <span style={{ fontWeight: 'normal' }}>
                            Alterar Estado de Bloqueio
                        </span>       

                        <br/>

                        <div className="unlockIconContainer" onClick={() => handleModal()}>
                            <FontAwesomeIcon icon={faUnlock} className="unlockIcon" size='sm' />
                        </div>     
                    </LoadingDiv>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '83%', justifyContent: 'space-between'}}>
                    <LoadingDiv loading={update} className='controlItem' style={{ height: '100%', width: '49%' }}>
                        
                                <span style={{ fontWeight: 'normal' }}>
                                    Últimos Eventos de Alerta
                                </span>

                                <div style={{ padding: '5px' }} />

                                {formatEvents()}
                    </LoadingDiv>
                
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '49%'}}>
                        <LoadingDiv loading={update} className='controlItem' style={{ height: '49%', width: '100%' }}>
                            <span style={{ fontWeight: 'normal' }}>
                                Quantidade de Testes Realizados
                            </span>

                            <div style={{ padding: '12px' }} />

                            <div style={{ display: 'flex', width: '100%', flex: 1 }}>
                                {/* <CustomLinePlot time={tests} /> */}
                                <CustomGroupBarChart time1={dashData ? dashData.dailyInitialTests : []} time2={dashData ? dashData.dailyRandomTests : []} />
                            </div>
                        </LoadingDiv>

                        <LoadingDiv loading={update} className='controlItem' style={{ height: '49%', width: '100%' }}>
                            <span style={{ fontWeight: 'normal' }}>
                                Resultados de Testes
                            </span>

                            <CustomHalfDonutChart
                                data={[
                                    { name: 'Bloqueado', value: dashData ? dashData.testResults.blocked : 0},
                                    { name: 'Liberado', value: dashData ? dashData.testResults.unblocked : 0},
                                    { name: 'Sem Sopro', value: dashData ? dashData.testResults.noBlow : 0},
                                    { name: 'Adiado', value: dashData ? dashData.testResults.postponed : 0},
                                ]}
                                totalData={dashData ? dashData.testResults.totalTests : 0}
                            />

                        </LoadingDiv>
                    </div>
                </div>
            </div>
        )
    }

    async function handleVehicleUnlock() {
        let selectedEtl = filteredEtl.filter((item: any) => item.vehicle_plate === unlockedPlate.label)

        let selectedSuntech = await getDataBaseCondition('suntechs', 'id', selectedEtl[0].device.suntech)

        let newData = {
            [selectedBlockOption.key]: true
        };

        if (selectedSuntech && selectedSuntech[0].is_connected === false){
            setModalUpdate(true)

            setSuccessfullCmd(false)
            setUnlockedPlate({})
            setSelectedBlockOption([])

            return
        }
        
        let response = await editDataBaseRow('suntechs', newData, selectedSuntech[0].id);

        if (response.status !== 'success') {
            setModalUpdate(true)

            setSuccessfullCmd(false)
            setUnlockedPlate({})
            setSelectedBlockOption([])

            return
        }

        setModalUpdate(true)
        setSuccessfullCmd(true)

        setUnlockedPlate({})
        setSelectedBlockOption([])
        
        //setModalUpdate(false)
        //setModalOpen(!modalOpen)
    }

    return (
        <div className="MainContainer">
            <CustomModal modalOpen={modalOpen} className='unlockModal'>
                <div style={{paddingTop: '3%', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <span style={{color: 'white', fontSize: '20px', marginLeft: '19.3%'}}>Alterar Estado de Bloqueio Remotamente</span>

                    <span style={{color: 'white', fontSize: '20px', display: 'flex', marginRight: '3%', cursor: 'pointer'}} onClick={() => handleModal()}>X</span>
                </div>

                {!modalUpdate && (
                    <>
                        <div style={{ marginTop: '3%' , width: '40%' }}>
                            <CustomDropBox
                                options={blockOptions}
                                value={selectedBlockOption}
                                setValue={setSelectedBlockOption}
                                placeholder='Comando'
                            />
                        </div>

                        <div style={{ marginTop: '3%' , width: '40%' }}>
                            <CustomDropBox
                                options={filteredPlates}
                                value={unlockedPlate}
                                setValue={setUnlockedPlate}
                                placeholder='Placa do Veículo'
                            />
                        </div>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <button 
                                className='buttonUnlock' 
                                onClick={async () => await handleVehicleUnlock()} 
                                style={{marginTop: '5%', width: '40%', opacity: Object.keys(unlockedPlate).length === 0 || Object.keys(selectedBlockOption).length === 0 ? 0.6 : 1, cursor: Object.keys(unlockedPlate).length === 0 || Object.keys(selectedBlockOption).length === 0 ? 'not-allowed' : 'pointer'}}
                                disabled={Object.keys(unlockedPlate).length === 0 || Object.keys(selectedBlockOption).length === 0}
                            >
                                <p style={{ fontSize: 'calc(5px + .5vw)' }}>Enviar</p>
                            </button>
                        </div>
                    </>
                )}

                {modalUpdate && (
                    <>
                        <FontAwesomeIcon icon={successfullCmd ? faCircleCheck : faCircleXmark} 
                         className="carIcon" size='sm' 
                         style={{ marginTop: '10%', borderRadius: '5rem', backgroundColor: successfullCmd ? '#00dd4d' : 'red'}}/>
                    
                        <span style={{color: 'white', fontSize: '17px', marginTop: '5%'}}>
                            {successfullCmd ? `Comando enviado com sucesso!` : `Comando não enviado! Veículo desconectado.`}
                        </span>
                    </>
                    
                )}
            </CustomModal>

            <TopBar />
            <div style={{ padding: '1rem' }} />

            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                {renderLeftContainer()}

                <div style={{ padding: '1.5%' }} />

                {renderRightContainer()}
            </div>

        </div>
    )
}