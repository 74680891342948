import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function CustomDropBox({
	options,
	value,
	setValue,
	placeholder,
	key = 'label',
	callbackKey = null,
	height = '3.2rem',
	color = 'white',
	fontSize = '12px',
	raw = false,
	secondaryKey = 'value',
	disabled = false,
}: any) {
	const sxOptions = {
		width: '100%',
		height: height,
		border: '1px solid white',
		backgroundColor: 'transparent',
		borderRadius: '10px',
		'.MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(14px, 1px) scale(0.8)',
			fontSize: fontSize,
			color: color,
		},
		'.MuiAutocomplete-listbox': {
			fontSize: fontSize,
		},
	};

	const sxTextFieldOptions = {
		'.MuiOutlinedInput-notchedOutline': {
			border: 'none',
			borderRadius: '10px',
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'.MuiInputBase-input': {
			fontSize: fontSize,
			color: color,
		},
		'.MuiInputLabel-outlined': {
			fontSize: '13px',
			opacity: 0.5,
			color: color,
		},
		'.Mui-disabled .MuiInputLabel-outlined': {
			color: color,
			opacity: 0.5, // Ajuste de opacidade para aparência mais clara
		},
		'.MuiAutocomplete-popupIndicator': {
			color: color,
			display: disabled ? 'none' : '',
		},
		'.MuiAutocomplete-clearIndicator': {
			color: color,
		},
	};

	// Se `raw` estiver desativado, converta os objetos para incluir labels
	if (!raw) {
		options = options.map((item: any) => ({
			...item,
			label: item[key],
			searchKey: `${item[key]} ${item[secondaryKey]}`.toLowerCase(), // Concatena label e secondaryKey para pesquisa
		}));
		
		if (callbackKey) {
			options = [{ label: callbackKey, key: 'default', searchKey: callbackKey.toLowerCase() }, ...options];
		}
	}
	
	// Determine o valor de exibição correto
	const displayValue = raw ? value : value?.label ?? '';

	return (
		<Autocomplete
			disablePortal
			id="combo-box-demo"
			options={options}
			sx={sxOptions}
			value={displayValue}
			disabled={disabled}
			onChange={(event, newValue) => setValue(raw ? newValue : newValue ?? {})}
			noOptionsText="não encontrado"
			filterOptions={(options, { inputValue }) =>
				options.filter((option) => option.searchKey.includes(inputValue.toLowerCase()))
			}
			renderInput={(params) => (
				<TextField
					{...params}
					label={placeholder}
					sx={sxTextFieldOptions}
					disabled={disabled}
				/>
			)}
		/>

	);
}
